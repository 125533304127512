import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";
import AddUserForm from "../AddUserForm";
import EditUserForm from "../EditUserForm";
import AddRole from "../AddRole";
import RolePermission from "../RolePermission";
import EditRole from "../EditRole";
import AddEventForm from "../AddEventForm";
import AddFeeForm from "../AddFeeForm";
import AddFeeTypeForm from "../AddFeeTypeForm";
import SetApprovalOrderForm from "../SetApprovalOrderForm";
import AddApprovalOrderForm from "../AddApprovalOrderForm";
import AddAnnouncementForm from "../AddAnnouncementForm";
import AddPlanForm from "../AddPlanForm";
import PaymentPlanForm from "../PaymentPlanForm";
import EditFee from "../EditFee";
import AddDiet from "../AddDiet";
import AddPeriod from "../AddPeriod";
import AddCategory from "../AddCategory";
import AddSubject from "../AddSubject";
import AddCenter from "../AddCenter";
import UploadResult from "../UploadResult";
import UploadTimetable from "../UploadTimetable";
import EditDiet from "../EditDiet";
import EditPeriod from "../EditPeriod";
import EditSubject from "../EditSubject";
import EditCenter from "../EditCenter";
import EditResult from "../EditResult";
import EditTimetable from "../EditTimetable";
import EventDetails from "../EventDetails";
import AddDepartment from "../AddDepartment";
import EditFeeType from "../EditFeeType";
import EditApprvalOderForm from "../EditApprovalOrderForm";
import EditApprovalWorkflow from "../EditApprovalWorkflow";
import EditDepartment from "../EditDepartment";
import IndividualDetails from "../IndividualDetails";
import DeclineForm from "../DeclineForm";
import UploadEdcucationResult from "../../screens/UploadEdcucationResult";
import UpdateBioDataModal from "../UpdateBioDataModal";
import EmembershipCardModal from "../EmembershipCardModal";
import EditCategory from "../EditCategory";
import EditAnnouncementForm from "../EditAnnouncementForm";
import EditEventForm from "./EditEventForm";
import ExamResultDetails from "../ExamResultDetails";
import ExamTimeTableDetails from "../ExamTimeTableDetails";
import AddProgramme from "../AddProgramme";
import EditProgramme from "../EditProgramme";
import PaymentSuccessModal from "../PaymentSuccessModal";
// import { useNavigate } from "react-router-dom";
import MemberUpgradeForm from "../MemberUpgradeForm";
import NYSCIndividualDetails from "../NYSCIndividualDetails";
import ApproveSuccessModal from "../ApproveSuccessModal";
import MemberProfilePaymentModal from "../MemberProfilePaymentModal";
import RegistrationPaymentDetails from "../RegistrationPaymentDetails";
import NYSCDeclineForm from "../NYSCDeclineForm";
import MembershipUpgradeDetails from "../MembershipUpgradeDetails";
import MembershipDeclineForm from "../MembershipDeclineForm";
import AddQualificationType from "../AddQualificationType";
import AddApplicableExemption from "../AddApplicableExemption";
import ExamIndividualDetails from "../ExamIndividualDetails";
import ExamDeclineForm from "../ExamDeclineForm";
import ClearOfflinePaymentForm from "../ClearOfflinePaymentForm";

const FormModal = () => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const formModal = useSelector((state) => state.modal.formModal);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={formModal?.status}
        onClose={() => {
          dispatch(setFormModal({ status: false, data: null }));
          // if (formModal?.data === 'PaymentSuccessModal' && formModal?.type === 'PaymentSuccessModal') {
          //   navigate('/mydues');
          // }
        }}
        size={formModal?.size}
      >
        <ModalOverlay />
        <ModalContent>
          {formModal?.title && (
            <ModalHeader
              borderBottom={"1px"}
              borderBottomColor={"#EFF4FA"}
              color={"#D11A2A"}
              fontSize={"medium"}
            >
              {formModal?.title}
            </ModalHeader>
          )}
          {
            (formModal?.type === "decline" || formModal?.type === "nyscdecline" || formModal?.type === "examDecline") ? null : formModal?.type === "membershipdecline" ? null : (
              <ModalCloseButton />
            )
          }

          <ModalBody pb={6} position={"relative"}>
            {formModal?.type === "addUser" ? (
              <AddUserForm />
            ) : formModal?.type === "editUser" ? (
              <EditUserForm />
            ) : formModal?.type === "addRole" ? (
              <AddRole />
            ) : formModal?.type === "rolePermission" ? (
              <RolePermission />
            ) : formModal?.type === "editRole" ? (
              <EditRole />
            ) : formModal?.type === "addEvent" ? (
              <AddEventForm />
            ) : formModal?.type === "editEvent" ? (
              <EditEventForm />
            ) : formModal?.type === "viewEventDetails" ? (
              <EventDetails />
            ) : formModal?.type === "addFeeType" ? (
              <AddFeeTypeForm />
            ) : formModal?.type === "editFee" ? (
              <EditFee />
            ) : formModal?.type === "editFeeType" ? (
              <EditFeeType />
            ) : formModal?.type === "addFee" ? (
              <AddFeeForm />
            ) : formModal?.type === "SetApprovalOrderForm" ? (
              <SetApprovalOrderForm />
            ) : formModal?.type === "EditApprovalOrderForm" ? (
              <EditApprvalOderForm />
            ) : formModal?.type === "AddApprovalOrderForm" ? (
              <AddApprovalOrderForm />
            ) : formModal?.type === "EditApprovalWorkflow" ? (
              <EditApprovalWorkflow />
            ) : formModal?.type === "addAnnouncement" ? (
              <AddAnnouncementForm />
            ) : formModal?.type === "addPlan" ? (
              <AddPlanForm />
            ) : formModal?.type === "paymentPlan" ? (
              <PaymentPlanForm />
            ) : formModal?.type === "addDiet" ? (
              <AddDiet />
            ) : formModal?.type === "addPeriod" ? (
              <AddPeriod />
            ) : formModal?.type === "addCategory" ? (
              <AddCategory />
            ) : formModal?.type === "AddSubject" ? (
              <AddSubject />
            ) : formModal?.type === "addCentre" ? (
              <AddCenter />
            ) : formModal?.type === "uploadResult" ? (
              <UploadResult />
            ) : formModal?.type === "uploadTimetable" ? (
              <UploadTimetable />
            ) : formModal?.type === "editDiet" ? (
              <EditDiet />
            ) : formModal?.type === "editPeriod" ? (
              <EditPeriod />
            ) : formModal?.type === "editSubject" ? (
              <EditSubject />
            ) : formModal?.type === "editCentre" ? (
              <EditCenter />
            ) : formModal?.type === "editResult" ? (
              <EditResult />
            ) : formModal?.type === "editTimetable" ? (
              <EditTimetable />
            ) : formModal?.type === "addDepartment" ? (
              <AddDepartment />
            ) : formModal?.type === "editDepartment" ? (
              <EditDepartment />
            ) : formModal?.type === "individualDetails" ? (
              <IndividualDetails />
            ) : formModal?.type === "nyscIndividualDetails" ? (
              <NYSCIndividualDetails />
            ) : formModal?.type === "examIndividualDetails" ? (
              <ExamIndividualDetails />
            ) : formModal?.type === "examDecline" ? (
              <ExamDeclineForm />
            ) : formModal?.type === "decline" ? (
              <DeclineForm />
            ) : formModal?.type === "nyscdecline" ? (
              <NYSCDeclineForm />
            ) : formModal?.type === "educationResult" ? (
              <UploadEdcucationResult />
            ) : formModal?.type === "updatebioData" ? (
              <UpdateBioDataModal />
            ) : formModal?.type === "emembershipcard" ? (
              <EmembershipCardModal />
            ) : formModal?.type === "editCategory" ? (
              <EditCategory />
            ) : formModal?.type === "editAnnouncement" ? (
              <EditAnnouncementForm />
            ) : formModal?.type === "examResultDetails" ? (
              <ExamResultDetails />
            ) : formModal?.type === "examTimeTableDetails" ? (
              <ExamTimeTableDetails />
            ) : formModal?.type === "addProgramme" ? (
              <AddProgramme />
            ) : formModal?.type === "editProgramme" ? (
              <EditProgramme />
            ) : formModal?.type === "PaymentSuccessModal" ? (
              <PaymentSuccessModal />
            ) : formModal?.type === "ApprovedPayment" ? (
              <ApproveSuccessModal />
            ) : formModal?.type === "NewMemberUpgrade" ? (
              <MemberUpgradeForm />
            ) : formModal?.type === "memberProfilePayment" ? (
              <MemberProfilePaymentModal />
            ): formModal?.type === "regPaymentDetails" ? (
              <RegistrationPaymentDetails />
            ): formModal?.type === "MembershipUpgrade" ? (
              <MembershipUpgradeDetails />
            ): formModal?.type === "membershipdecline" ? (
              <MembershipDeclineForm />
            ): formModal?.type === "addQualification" ? (
              <AddQualificationType data={formModal?.data} />
            ): formModal?.type === "AddExemption" ? (
              <AddApplicableExemption data={formModal?.data} />
            ): formModal?.type === "clearOfflinePayment" ? (
              <ClearOfflinePaymentForm />
            )
            
              : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormModal;

import {
    Alert,
    AlertIcon,
    AlertTitle,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
  } from "@chakra-ui/react";
  import React, { useState } from "react";
  import { ClearOfflinePaymentFormSchema } from "../utils/formValidationSchema";
  import { Formik } from "formik";
  import { useDispatch } from "react-redux";
  import { setFormModal } from "../redux/slices/modalSlice";
  
  const ClearOfflinePaymentForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const initialValues = {
      amountPaid: "",
      paymentDate: "",
      status: "",
    };
  
    const handleSubmit = async (values) => {
      try {
        setLoading(true);
        // Call API or dispatch Redux action here
        console.log("Form Values Submitted:", values);
  
        // Simulate success
        setTimeout(() => {
          setLoading(false);
          dispatch(setFormModal({ status: false, data: null }));
        }, 1000);
      } catch (err) {
        setError("Something went wrong. Please try again.");
        setLoading(false);
      }
    };
  
    return (
      <>
        {error && (
          <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        )}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={ClearOfflinePaymentFormSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {/* Amount Paid Field */}
              <FormControl mt={4} isInvalid={errors.amountPaid && touched.amountPaid} color="#121212">
                <FormLabel htmlFor="amountPaid" fontSize={"16px"}>
                  Amount Paid
                </FormLabel>
                <Input
                  placeholder="Enter amount"
                  fontSize={"small"}
                  bg={"#F3F3F3"}
                  name="amountPaid"
                  focusBorderColor="#ffffff"
                  borderRadius={"0"}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.amountPaid}
                />
                {touched.amountPaid && errors.amountPaid && (
                  <FormErrorMessage>{errors.amountPaid}</FormErrorMessage>
                )}
              </FormControl>
  
              {/* Payment Date Field */}
              <FormControl mt={4} isInvalid={errors.paymentDate && touched.paymentDate} color="#121212">
                <FormLabel htmlFor="paymentDate" fontSize={"16px"}>
                  Payment Date
                </FormLabel>
                <Input
                  type="date"
                  placeholder="Pick a Date"
                  borderRadius={"0"}
                  fontSize={"small"}
                  bg={"#F3F3F3"}
                  name="paymentDate"
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.paymentDate}
                />
                {touched.paymentDate && errors.paymentDate && (
                  <FormErrorMessage>{errors.paymentDate}</FormErrorMessage>
                )}
              </FormControl>
  
              {/* Status Field */}
              <FormControl mt={4} isInvalid={errors.status && touched.status} color="#121212">
                <FormLabel htmlFor="status" fontSize={"16px"}>
                  Status
                </FormLabel>
                <Select
                  placeholder="Select status"
                  fontSize={"small"}
                  bg={"#F3F3F3"}
                  name="status"
                  focusBorderColor="#ffffff"
                  borderRadius={"0"}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.status}
                >
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                  <option value="rejected">Rejected</option>
                </Select>
                {touched.status && errors.status && (
                  <FormErrorMessage>{errors.status}</FormErrorMessage>
                )}
              </FormControl>
  
              {/* Buttons */}
              <Flex justifyContent={"center"} alignItems={"center"} gap={5}>
                <Button
                  mt={10}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  isLoading={loading}
                  loadingText="Saving..."
                >
                  Save
                </Button>
                <Button
                  mt={10}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  width={"118px"}
                  background={""}
                  _hover={{ background: " " }}
                  color={"#8F9BB3"}
                  onClick={() => {
                    dispatch(setFormModal({ status: false, data: null }));
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          )}
        </Formik>
      </>
    );
  };
  
  export default ClearOfflinePaymentForm;
  
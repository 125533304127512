import React, { useState } from "react";
import ExamlistingReportTable from "../components/ExamlistingReportTable";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchExamListingReport } from "../redux/slices/report2Slice";
import { setFormModal } from "../redux/slices/modalSlice";
import { setloadTost } from "../redux/slices/toastSlice";

function ExamListingReport() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ setError] = useState("");

  const examListingReportData = useSelector((state) => state?.report2)
  console.log('examListingReportData', examListingReportData)
  const initialValues = {
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
      const formData = {
        membershipNumber: values?.membershipNumber,
        examPeriodId: values?.examPeriodId,
        examCategoryId: values?.examCategoryId,
        startDate: values?.startDate,
        endDate: values?.endDate,
      };
      const response = await dispatch(fetchExamListingReport(formData));
       console.log('response',response);
      if (response?.payload?.isSuccess === true) {
        setLoading(false);
        dispatch(fetchExamListingReport());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(
          setloadTost({
            title: "Report generated",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          })
        );
      } else {
        setLoading(false);
        setError(response?.payload?.errors || "Failed to generate report");
      }
    } catch (error) {
      setError(
        error?.payload?.message ||
          "Failed to generate report, please check your network"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        bgColor={"white"}
        height={["850px", "", "370px"]}
        px={"30px"}
        py={"20px"}
        mb={5}
      >
        <Text color={"#3A3541DE"} fontSize={"16px"} fontWeight={500}>
          Search Filters
        </Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={approvalReportSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl
                  mt={4}
                  isInvalid={errors.startDate && touched.startDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="startDate" fontSize={"16px"}>
                    Start Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="startDate"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startDate}
                  />
                  {touched.startDate && errors.startDate && (
                    <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.endDate && touched.endDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="endDate" fontSize={"16px"}>
                    End Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="endDate"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex justifyContent={"flex-end"} alignItems={"center"}>
                <Button
                  mt={10}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  onClick={() => {
                    dispatch(setFormModal({ status: false, data: null }));
                }}
                  isLoading={loading}
                  loadingText="fetching ..."
                >
                  Fetch Report
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      <ExamlistingReportTable />
    </>
  );
}

export default ExamListingReport;

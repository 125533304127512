import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  memberPayHistory: [],
  approvalReport: [],
  memberUpgradeReport: [],
  memberReport: [],
  memberDetails: null,
  birthdayReport: [],
  inductionReport: [],
  examCenterReport: [],
  examApplicantReport: [],
  memberDuesReport: [],
  examResultListing: [],
  memberOutstandingDuesReport: [],
};

export const fetchMemberPayHistory = createAsyncThunk(
  "report2/fetchMemberPayHistory",
  async () => {
    return httpRequest(
      `${BASE_URL}/v2/Report/member-payment-history?PageSize=1000`
    );
  }
);

export const fetchApprovalReport = createAsyncThunk(
  "report2/fetchApprovalReport",
  async ({ startDate, endDate, approvalReportCategory }) => {
    return httpRequest(
      `${BASE_URL}/v2/Report/approval?PageSize=1000&startDate=${startDate}&endDate=${endDate}&approvalReportCategory=${approvalReportCategory}`
    );
  }
);

export const fetchMemberUpgradeReport = createAsyncThunk(
  "report2/fetchMemberUpgradeReport",
  async ({ startDate, endDate }) => {
    return httpRequest(
      `${BASE_URL}/v2/Report/member-upgrade-report?PageSize=1000&StartDate=${startDate}&EndDate=${endDate}`
    );
  }
);

export const fetchMemberReport = createAsyncThunk(
  "report2/fetchMemberReport",
  async ({ MembershipCategory, startDate, endDate }) => {
    return httpRequest(
      `${BASE_URL}/v2/Report/member?PageSize=1000&MembershipCategory=${MembershipCategory}&StartDate=${startDate}&EndDate=${endDate}`
    );
  }
);

export const fetchMemberDetails = createAsyncThunk(
  "report2/fetchMemberDetails",
  async (memberNumber, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Report/member-details`,
        "post",
        memberNumber
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchBirthdayReport = createAsyncThunk(
  "report2/fetchbirthdayreport",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Report/birthday?PageSize=1000`);
  }
);

export const fetchInductionReport = createAsyncThunk(
  "report2/fetchinductionreport",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Report/induction?PageSize=1000`);
  }
);

export const fetchExamCentreReport = createAsyncThunk(
  "report2/fetchexamcentrereport",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Report/exam-centre?PageSize=1000`);
  }
);

export const fetchExamApplicantReport = createAsyncThunk(
  "report2/fetchExamApplicantReport",
  async ({ ExamPeriodId, ExamCentreId, startDate, endDate }) => {
    return httpRequest(
      `${BASE_URL}/v2/Report/exam-applicant?PageSize=1000&ExamPeriodId=${ExamPeriodId}&ExamCentreId=${ExamCentreId}&StartDate=${startDate}&EndDate=${endDate}`
    );
  }
);

export const fetchExamListingReport = createAsyncThunk(
  "report2/fetchExamListingReport",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Report/exam-result-listing?Page=1&PageSize=1000`,
        "post",
        arg
      );

      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchPaymentTypeReport = createAsyncThunk(
  "report2/fetchPaymentTypeReport",
  async ({ startDate, endDate }) => {
    return httpRequest(
      `${BASE_URL}/v2/Report/payment-type-report?PageSize=1000&StartDate=${startDate}&EndDate=${endDate}`
    );
  }
);

export const fetchMemberDuesReport = createAsyncThunk(
  "report2/fetchMemberDuesReport",
  async ({ membershipCategory, startDate, endDate }) => {
    return httpRequest(
      `${BASE_URL}/v2/Report/member-dues-management?PageSize=1000&membershipCategory=${membershipCategory}&StartDate=${startDate}&EndDate=${endDate}`
    );
  }
);

export const fetchMemberOutstandingDuesReport = createAsyncThunk(
    "report2/fetchMemberOutstandingDuesReport",
    async ({ startDate, endDate }) => {
      return httpRequest(
        `${BASE_URL}/v2/Report/member-outstanding-dues?PageSize=1000&StartDate=${startDate}&EndDate=${endDate}`
      );
    }
  );
  

export const fetchTransactionLogReport = createAsyncThunk(
  "report2/fetchTransactionLogReport",
  async (membershipId, startDate, endDate, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Report/transaction-log-report?page=1&PageSize=1000&StartDate=${startDate}&EndDate=${endDate}`,
        "post",
        membershipId
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const report2Slice = createSlice({
  name: "report2",
  initialState,

  extraReducers: (builder) => {
    //get member payment history:
    builder.addCase(fetchMemberPayHistory.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMemberPayHistory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.memberPayHistory = payload;
      state.error = payload?.error?.description || null;
    });

    builder.addCase(fetchMemberPayHistory.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get approval report:
    builder.addCase(fetchApprovalReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchApprovalReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.approvalReport = payload;
      state.error = null;
    });

    builder.addCase(fetchApprovalReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get member upgrade report:
    builder.addCase(fetchMemberUpgradeReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      fetchMemberUpgradeReport.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.memberUpgradeReport = payload;
        state.error = null;
      }
    );

    builder.addCase(fetchMemberUpgradeReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get member report:
    builder.addCase(fetchMemberReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMemberReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.memberReport = payload;
      state.error = null;
    });

    builder.addCase(fetchMemberReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // get birthday report:
    builder.addCase(fetchBirthdayReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchBirthdayReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.birthdayReport = payload;
      state.error = null;
    });

    builder.addCase(fetchBirthdayReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // get induction report:
    builder.addCase(fetchInductionReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchInductionReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.inductionReport = payload;
      state.error = null;
    });

    builder.addCase(fetchInductionReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // get exam centre report:
    builder.addCase(fetchExamCentreReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamCentreReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examCenterReport = payload;
      state.error = null;
    });

    builder.addCase(fetchExamCentreReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get member details:
    builder.addCase(fetchMemberDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMemberDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.memberDetails = payload;
      state.error = null;
    });

    builder.addCase(fetchMemberDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // get exam applicant report:
    builder.addCase(fetchExamApplicantReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      fetchExamApplicantReport.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.examApplicantReport = payload;
        state.error = null;
      }
    );

    builder.addCase(fetchExamApplicantReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get member dues report:
    builder.addCase(fetchMemberDuesReport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMemberDuesReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.memberDuesReport = payload;
      state.error = null;
    });

    builder.addCase(fetchMemberDuesReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

      // get exam listing report 
      builder.addCase(fetchExamListingReport.pending, (state) => {
        state.loading = true;
    });

    builder.addCase(fetchExamListingReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.examResultListing = payload;
        state.error = null;
    });

    builder.addCase(fetchExamListingReport.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
    });

      // get member outstanding dues report:
      builder.addCase(fetchMemberOutstandingDuesReport.pending, (state) => {
        state.loading = true;
      });
  
      builder.addCase(fetchMemberOutstandingDuesReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.memberOutstandingDuesReport = payload;
        state.error = null;
      });
  
      builder.addCase(fetchMemberOutstandingDuesReport.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
      });
  },
});

export default report2Slice.reducer;

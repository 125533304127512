import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import MembershipOutstandingDuesReportTable from "../components/MembershipOutstandingDuesReportTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberOutstandingDuesReport } from "../redux/slices/report2Slice";
import { setloadTost } from "../redux/slices/toastSlice";

const MemberOutstandingDuesReport = () => {
  const dispatch = useDispatch();
  const memberOutstandingDuesReport = useSelector((state) => state?.report2);
  console.log("memberOutstandingDuesReport", memberOutstandingDuesReport);

  const initialValues = {
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values, actions) => {
    const response = await dispatch(
      fetchMemberOutstandingDuesReport({
        startDate: values?.startDate,
        endDate: values?.endDate,
      })
    );
    if (response?.payload?.isSuccess === false) {
      dispatch(
        setloadTost({
          title: "Error",
          description:
            response?.payload?.errors?.map((errors) => errors) ||
            response?.payload?.error?.description ||
            "Failed to get report",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
    } else {
      dispatch(
        setloadTost({
          title: "Success",
          description: `report generated successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
      actions.resetForm();
    }
  };

  return (
    <>
      <Box
        bgColor={"white"}
        height={["550px", "", "270px"]}
        px={"30px"}
        py={"20px"}
        pb={"10px"}
      >
        <Text color={"#3A3541DE"} fontSize={"16px"} fontWeight={500}>
          Search Filters
        </Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={paymentSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl
                  mt={4}
                  isInvalid={errors.startDate && touched.startDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="startDate" fontSize={"16px"}>
                    Start Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="startDate"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startDate}
                  />
                  {touched.startDate && errors.startDate && (
                    <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.endDate && touched.endDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="endDate" fontSize={"16px"}>
                    End Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="endDate"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl></FormControl>
                <FormControl></FormControl>
              </Flex>
              <Flex justifyContent={"flex-end"} alignItems={"center"}>
                <Button
                  mt={10}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  // isLoading={loading}
                  // loadingText="Saving ..."
                >
                  Fetch Report
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      {memberOutstandingDuesReport?.memberOutstandingDuesReport?.data
      ? <MembershipOutstandingDuesReportTable memberOutstandingDuesReport={memberOutstandingDuesReport?.memberOutstandingDuesReport?.data} />
      : null }
      </>
  );
};

export default MemberOutstandingDuesReport;
